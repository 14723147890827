import React from "react";
import { Link } from "react-router-dom";
import HomeSingleBlogLeftSide from "../../../../components/HomeSingleBlogLeftSide/HomeSingleBlogLeftSide";
import HomeSingleBlogRightSide from "../../../../components/HomeSingleBlogRightSide/HomeSingleBlogRightSide";
import { sendWhatsapp } from "../../../../utils";

const HomeBlogs = () => {
  return (
    <>
      <section className="latest-news-area pt-115 pb-20">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-7">
              <div className="section-title pos-rel mb-75">
                <div className="section-icon">
                  <img
                    className="section-back-icon back-icon-left"
                    src="img/section/section-back-icon.png"
                    alt=""
                  />
                </div>
                <div className="section-text pos-rel">
                  <h5>Novedades</h5>
                  <h1>Nuestras jornadas</h1>
                </div>
                <div className="section-line pos-rel">
                  <img src="img/shape/section-title-line.png" alt="" />
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <HomeSingleBlogLeftSide
              image="28"
              title="Jornada 28 de Agosto 2024"
              description=""
            />
            <HomeSingleBlogLeftSide
              image="27"
              title="Jornada 13 de Agosto 2024"
              description=""
            />
            <HomeSingleBlogLeftSide
              image="26"
              title="Semanda Mundial Latancia Materna"
              description=""
            />
            <HomeSingleBlogLeftSide
              image="25"
              title="Jornada 18 de Julio 2024"
              description=""
            />
            <HomeSingleBlogLeftSide
              image="24"
              title="Jornada 2 de Julio 2024"
              description=""
            />
            <HomeSingleBlogLeftSide
              image="23"
              title="Jornada 15 de Mayo 2024"
              description=""
            />
            <HomeSingleBlogLeftSide
              image="22"
              title="Jornada 15 de Febrero 2024"
              description=""
            />
            <HomeSingleBlogLeftSide
              image="21"
              title="Jornada 28 de Noviembre 2023"
              description=""
            />
            <HomeSingleBlogLeftSide
              image="20"
              title="Jornada 4 de Octubre 2023"
              description=""
            />
            <HomeSingleBlogLeftSide
              image="19"
              title="Jornada 4 de Septiembre 2023"
              description=""
            />
            <HomeSingleBlogLeftSide
              image="18"
              title="Jornada 14 de Agosto 2023"
              description=""
            />
            <HomeSingleBlogLeftSide
              image="17"
              title="Semana mundial latancia materna 2023"
              description=""
            />
            <HomeSingleBlogLeftSide
              image="16"
              title="Jornada 17 de Mayo 2023"
              description=""
            />
            <HomeSingleBlogLeftSide
              image="15"
              title="Jornada 25 de Abril 2023"
              description=""
            />
            <HomeSingleBlogLeftSide
              image="14"
              title="Jornada 5 de Abril 2023"
              description=""
            />
            <HomeSingleBlogLeftSide
              image="13"
              title="1er Aniversario"
              description=""
            />
            <HomeSingleBlogLeftSide
              image="12"
              title="Jornada 24 de Enero 2023"
              description=""
            />
            <HomeSingleBlogLeftSide
              image="11"
              title="Día mundial del médico"
              description=""
            />
            <HomeSingleBlogLeftSide
              image="10"
              title="Jornada 10 de Noviembre 2022"
              description=""
            />
            {/* <HomeSingleBlogLeftSide
                            image="9"
                            title="Jornada 11 de Octubre 2022"
                            description=""
                        />
                        <HomeSingleBlogLeftSide
                            image="8"
                            title="Jornada 31 de Agosto 2022"
                            description=""
                        />
                        <HomeSingleBlogLeftSide
                            image="7"
                            title="Semana Mundial de la Lactancia Materna 2022"
                            description=""
                        />
                        <HomeSingleBlogLeftSide
                            image="6"
                            title="Jornada 10 de Agosto 2022"
                            description=""
                        />
                        <HomeSingleBlogLeftSide
                            image="5"
                            title="Jornada 11 de Julio 2022"
                            description=""
                        />
                        <HomeSingleBlogLeftSide
                            image="0"
                            title="Jornada 27 de Mayo 2022"
                            description=""
                        />
                        <HomeSingleBlogLeftSide
                            image="1"
                            title="Jornada 4 de Mayo 2022"
                            description=""
                        /> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeBlogs;
